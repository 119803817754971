<template>
  <v-container fluid>
    <v-col cols="12">
      <v-card elevation="0">
        <TranslationsHeader
          :module-items="moduleItems"
          :language-items="languageItems"
          :is-expanded="isExpanded"
          :has-items="!!filteredTree.length"
          :loading="restoringTranslations"
          :current-language="selectedLanguage"
          :has-search="!!search"
          :module-selected="!!selectedModule"
          :expansion-level="expansionLevel"
          @translation-updated="onTranslationUpdated"
          @restore="showRestoreDialog"
          @module-change="selectedModule = $event"
          @language-change="handleLanguageChange"
          @search="search = $event"
          @handle-expand="handleExpand"
        />

        <v-card-text>
          <div class="tree-container">
            <v-treeview
              v-model="tree"
              :items="paginatedItems"
              :open="openedModules"
              item-key="id"
              open-on-click
              :hoverable="false"
              dense
              class="custom-tree"
              expand-icon=""
              :off-icon="''"
              :on-icon="''"
              :activatable="false"
            >
              <template v-slot:prepend="{ item }">
                <template v-if="item.type !== 'translation'">
                  <v-icon 
                    v-if="item.type === 'module'"
                    :class="{ 'grey--text': isEmptyNode(item) }"
                  >mdi-folder</v-icon>
                  <v-icon 
                    v-else-if="item.type === 'group'"
                    :class="{ 'grey--text': isEmptyNode(item) }"
                  >mdi-folder-outline</v-icon>
                </template>
                <template v-else>
                  <div style="width: 0; padding: 0; margin: 0"></div>
                </template>
              </template>

              <template v-slot:label="{ item }">
                <div class="d-flex align-center justify-space-between full-width">
                  <div class="d-flex align-center flex-grow-1">
                    <template v-if="item.type !== 'translation'">
                      <div class="tree-label-container">
                        <div class="d-flex align-center">
                          <!-- Mostrar el campo de edición si está activo -->
                          <template v-if="activeTranslation === item.labelKey">
                            <v-text-field
                              ref="translationInput"
                              :value="item.displayName"
                              @input="updateLabelValue(item, $event)"
                              @blur="onTranslationBlur({
                                id: item.labelKey,
                                key: item.labelKey,
                                translation: item.displayName,
                                type: 'translation',
                                isCustom: true
                              })"
                              @keyup.enter="onTranslationBlur({
                                id: item.labelKey,
                                key: item.labelKey,
                                translation: item.displayName,
                                type: 'translation',
                                isCustom: true
                              })"
                              @keyup.esc="activeTranslation = null"
                              outlined
                              dense
                              hide-details="auto"
                              class="label-edit-field"
                              @click.stop
                              @mousedown.stop
                            ></v-text-field>
                          </template>
                          <!-- Mostrar el label normal si no está en edición -->
                          <template v-else>
                            <span 
                              class="tree-label" 
                              :class="{ 'grey--text': isEmptyNode(item) }"
                              v-html="highlightText(item.displayName)"
                            ></span>
                            <v-tooltip bottom v-if="item.hasLabel">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon 
                                  x-small 
                                  class="ml-2 edit-icon"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click.stop="startLabelEdit(item)"
                                >
                                  mdi-pencil
                                </v-icon>
                              </template>
                              <span>{{ $t('common.buttons.edit') }}</span>
                            </v-tooltip>
                          </template>
                        </div>
                      </div>
                    </template>

                    <div v-if="item.type === 'translation'" class="translation-field" :class="{'ml-0': item.type === 'translation'}">
                      <template v-if="activeTranslation === item.id">
                        <v-text-field
                          ref="translationInput"
                          :value="item.translation || item.fallback"
                          @input="item.translation = $event"
                          @blur="onTranslationBlur(item)"
                          @keyup.enter="onTranslationBlur(item)"
                          @keyup.esc="activeTranslation = null"
                          :loading="item.saving"
                          :hint="!item.isCustom ? $t('translations.editor.usingFallback') : ''"
                          :persistent-hint="!item.isCustom"
                          outlined
                          dense
                          hide-details="auto"
                          clearable
                          @click.stop
                          @mousedown.stop
                        >
                          <template v-slot:append>
                            <v-icon v-if="!item.isCustom" small color="grey" title="Using fallback translation">
                              mdi-backup-restore
                            </v-icon>
                          </template>
                        </v-text-field>
                      </template>
                      <template v-else>
                        <div 
                          class="translation-preview" 
                          @click.stop="onTranslationClick(item)"
                          @mousedown.stop
                        >
                          <span 
                            :ref="`preview-${item.id}`"
                            class="preview-text"
                            v-html="highlightText(item.translation || item.fallback)"
                          ></span>
                          <v-icon x-small class="ml-2 flex-shrink-0">mdi-pencil</v-icon>
                        </div>
                      </template>
                    </div>
                  </div>

                  <div v-if="item.type !== 'translation'" class="ml-4 stats">
                    <span class="caption grey--text text--darken-1">
                      {{ getFilteredStats(item).groups }} {{ item.type === 'module' ? 
                        $t('translations.editor.stats.groups') : 
                        $t('translations.editor.stats.translations') 
                      }}
                    </span>
                    <v-chip x-small class="ml-2" outlined small>
                      {{ getFilteredStats(item).customized }}/{{ getFilteredStats(item).total }}
                    </v-chip>
                  </div>
                </div>
              </template>
            </v-treeview>
          </div>

          <v-data-footer 
            :items-per-page-text="$t('common.table.itemsPerPageText')"
            :page-text="'{0}-{1} ' + $t('common.table.pageText') + ' {2}'" 
            :options="{
              page: page,
              itemsPerPage: itemsPerPage
            }"
            :pagination="{
              page: page,
              itemsPerPage: itemsPerPage,
              pageCount: Math.ceil(filteredTree.length / itemsPerPage),
              itemsLength: filteredTree.length,
              pageStart: (page - 1) * itemsPerPage,
              pageStop: Math.min(page * itemsPerPage, filteredTree.length)
            }"
            @update:options="handlePageChange"
            :disable-items-per-page="true" 
            show-first-last-page
          ></v-data-footer>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- Diálogo de confirmación -->
    <v-dialog v-model="showDialog" max-width="400">
      <v-card>
        <v-card-title class="headline secondary t-bw-secondary--text">
          {{ $t('translations.editor.restoreConfirmTitle') }}
        </v-card-title>
        <v-card-text class="pa-6">
          {{ $t('translations.editor.restoreConfirmMessage') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small text color="neuter" @click="showDialog = false">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn small elevation="0" color="error" class="t-bw-error--text"  
            @click="restoreDefaultTranslations"
            :loading="restoringTranslations"
          >
            {{ $t('translations.editor.restore') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TranslationsHeader from './components/TranslationsHeader.vue';
import LanguajeService from '@/services/LanguajeService';
import i18n, { getFallbackMessage } from '@/plugins/i18n';
import _ from 'lodash';
import { TRANSLATIONS_CONFIG } from '@/module/configuration/translations/configuration';
import PermisionService from '@/services/PermisionService';

export default {
  name: 'TranslationEditor',
  
  components: {
    TranslationsHeader,
  },

  data() {
    return {
      originalLocale: null,
      search: '',
      loading: false,
      selectedLanguage: LanguajeService.getLenguajeName(),
      moduleTree: [],
      openedModules: [],
      saving: false,
      activeTranslation: null,
      page: 1,
      itemsPerPage: 15,
      tree: [],
      isExpanded: false,
      filteredTree: [],
      options: {},
      selectedModule: null,
      showDialog: false,
      restoringTranslations: false,
      expansionLevel: 0, // 0: collapsed, 1: first level, 2: fully expanded
      previousOpenedLength: 0, // Añadir esta nueva propiedad
    };
  },

  computed: {
    languageItems() {
      return [
        { text: 'English', value: 'ENGLISH' },
        { text: 'Español', value: 'ESPANNOL' },
        { text: 'Português', value: 'PORTUGUESE' }
      ];
    },

    currentLocale() {
      return LanguajeService.getKeyByLenguajeName(this.selectedLanguage);
    },

    pageCount() {
      return Math.ceil(this.filteredTree.length / this.itemsPerPage);
    },

    paginatedItems() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredTree.slice(start, end);
    },

    moduleItems() {
      return this.moduleTree.map(module => ({
        text: module.displayName || this.capitalizeFirstLetter(module.name),
        value: module.id
      }));
    },

    canEdit() {
      return this.checkAdmin() || this.check([{ domain: 'Translations', permisions: ['Write'] }]);
    },

    canView() {
      return this.checkAdmin() || this.check([{ domain: 'Translations', permisions: ['Read'] }]);
    },

    getExpandButtonColor() {
      if (this.expansionLevel === 0) return 'primary';
      if (this.expansionLevel === 1) return 'primary';
      return 'grey lighten-1';
    },

    getExpandIcon() {
      if (this.expansionLevel === 0) return 'mdi-chevron-right';
      if (this.expansionLevel === 1) return 'mdi-chevron-double-right';
      return 'mdi-chevron-down';
    },

    getExpandText() {
      if (this.expansionLevel === 0) return this.$t('translations.editor.expandFirstLevel');
      if (this.expansionLevel === 1) return this.$t('translations.editor.expandAll');
      return this.$t('translations.editor.collapseAll');
    }
  },

  methods: {
    capitalizeFirstLetter(text) {
      if (!text || typeof text !== 'string') {
        return '';
      }
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    },

    async loadTranslations(skipFetch = false) {
      if (!this.canView) {
        this.$router.push({ name: 'home' });
        return;
      }
      this.loading = true;
      try {
        // Obtener traducciones completas del servidor
        if (!skipFetch) {
          await LanguajeService.fetchCustomTranslations(this.currentLocale);
        }
        
        // Obtener mensajes completos
        const fallbackMessages = getFallbackMessage(this.currentLocale);
        const serverTranslations = LanguajeService.customTranslations[this.currentLocale] || {};
        
        // Filtrar módulos excluidos solo para la vista
        const filteredMessages = _.omit(fallbackMessages, TRANSLATIONS_CONFIG.excludedModules);
        const filteredServerTranslations = _.omit(serverTranslations, TRANSLATIONS_CONFIG.excludedModules);
        
        // Transformar a estructura de árbol con mensajes filtrados
        this.moduleTree = this.transformToTree(filteredMessages, filteredServerTranslations);
        this.page = 1;
        this.updateFilteredItems();
      } catch (error) {
        console.error('Error loading translations:', error);
        this.moduleTree = [];
        this.filteredTree = [];
      } finally {
        this.loading = false;
      }
    },

    transformToTree(messages, serverTranslations) {
      const tree = [];
      
      Object.entries(messages).forEach(([moduleName, moduleData]) => {
        // Ignorar módulos excluidos
        if (TRANSLATIONS_CONFIG.shouldExcludeModule(moduleName)) {
          return;
        }

        if (typeof moduleData !== 'object') return;

        // Obtener el label personalizado del servidor si existe
        const customLabel = _.get(serverTranslations, `${moduleName}.label`);
        
        const moduleNode = {
          id: moduleName,
          name: moduleName,
          // Usar el label del servidor si existe, sino usar el label local o el nombre capitalizado
          displayName: customLabel || moduleData.label || this.capitalizeFirstLetter(moduleName),
          type: 'module',
          children: [],
          hasLabel: !!(customLabel || moduleData.label),
          labelKey: `${moduleName}.label`,
          isCustom: !!customLabel
        };

        // Process module data excluding the label property
        const dataToProcess = { ...moduleData };
        delete dataToProcess.label;

        this.processModuleData(
          moduleNode.children,
          dataToProcess,
          _.get(serverTranslations, moduleName, {}),
          moduleName
        );

        if (moduleNode.children.length > 0 || moduleNode.hasLabel) {
          tree.push(moduleNode);
        }
      });

      return tree;
    },

    processModuleData(children, currentData, serverData, parentKey) {
      Object.entries(currentData).forEach(([key, value]) => {
        const fullKey = parentKey ? `${parentKey}.${key}` : key;

        if (typeof value === 'object' && value !== null) {
          // Obtener el label personalizado del servidor para grupos
          const customLabel = _.get(serverData, `${key}.label`);

          const groupNode = {
            id: fullKey,
            name: key,
            // Usar el label del servidor si existe, sino usar el label local o el nombre capitalizado
            displayName: customLabel || (value.label || this.capitalizeFirstLetter(key)),
            type: 'group',
            children: [],
            hasLabel: !!(customLabel || value.label),
            labelKey: `${fullKey}.label`,
            isCustom: !!customLabel
          };

          // Process group data excluding the label property
          const dataToProcess = { ...value };
          delete dataToProcess.label;

          this.processModuleData(
            groupNode.children,
            dataToProcess,
            serverData[key] || {},
            fullKey
          );

          if (groupNode.children.length > 0 || groupNode.hasLabel) {
            children.push(groupNode);
          }
        } else {
          // Es una traducción normal
          children.push({
            id: fullKey,
            name: key,
            type: 'translation',
            translation: _.get(serverData, key),
            fallback: value,
            isCustom: _.has(serverData, key),
            saving: false,
            key: fullKey
          });
        }
      });
    },

    async saveTranslation(item) {
      if (item.saving) return;

      const newValue = item.translation?.trim();

      // Si el valor está vacío, restaurar al fallback
      if (!newValue) {
        item.translation = '';
        item.isCustom = false;
        const parentModule = this.findParentModule(item);
        if (parentModule) {
          this.calculateStats(parentModule);
        }
        return;
      }

      item.saving = true;
      try {
        const result = await LanguajeService.saveCustomTranslation(
          this.currentLocale,
          item.key,
          newValue
        );
        
        if (result.success) {
          item.isCustom = true;
          item.translation = newValue;
          item.originalTranslation = newValue;
        }
      } catch (error) {
        console.error('Error saving translation:', error);
        // Restaurar al valor original en caso de error
        item.translation = item.originalTranslation || item.fallback;
        item.isCustom = !!item.originalTranslation;
        this.$toast?.error(this.$t('common.error.save'));
      } finally {
        item.saving = false;
        this.activeTranslation = null;
      }
    },

    async updateTranslationInTree(key, value, expansionState = null) {
      const updateNode = (items) => {
        for (const item of items) {
          // Actualizar el displayName si es un label
          if (item.labelKey === key) {
            item.displayName = value;
            return true;
          }
          if (item.key === key) {
            item.translation = value;
            item.isCustom = true;
            item.originalTranslation = value;
            return true;
          }
          if (item.children) {
            if (updateNode(item.children)) return true;
          }
        }
        return false;
      };
      
      updateNode(this.moduleTree);
      this.updateFilteredItems();
      
      await this.$nextTick();
      this.$forceUpdate();
    },

    async onLanguageChange() {
      try {
        const previousLocale = this.currentLocale;
        LanguajeService.changeLanguaje(this.selectedLanguage);
        
        // Forzar recarga de traducciones
        await LanguajeService.fetchCustomTranslations(this.currentLocale);
        await this.loadTranslations(true);
        
        // Actualizar i18n
        await this.$nextTick();
        this.$forceUpdate();
        
        // Notificar cambio exitoso
        this.$toast?.success(this.$t('translations.editor.languageChanged'));
      } catch (error) {
        console.error('Error changing language:', error);
        this.$toast?.error(this.$t('translations.editor.languageChangeError'));
      }
    },

    async handleLanguageChange(newLanguage) {
      this.selectedLanguage = newLanguage;
      await this.onLanguageChange();
    },

    calculateStats(node) {
      const stats = {
        total: 0,
        customized: 0,
        groups: 0
      };

      if (node.children) {
        stats.groups = node.children.filter(child =>
          child.type === (node.type === 'module' ? 'group' : 'translation')
        ).length;

        node.children.forEach(child => {
          if (child.type === 'translation') {
            stats.total++;
            if (child.isCustom) stats.customized++;
          } else {
            const childStats = this.calculateStats(child);
            stats.total += childStats.total;
            stats.customized += childStats.customized;
          }
        });
      }

      node.stats = stats;
      return stats;
    },

    getFilteredStats(item) {
      const countCustomizations = (node) => {
        let total = 0;
        let customized = 0;
        let groups = 0;

        // Obtener las traducciones del servidor para este módulo/grupo
        const serverTranslations = LanguajeService.customTranslations[this.currentLocale] || {};

        if (node.children) {
          groups = node.children.filter(child => 
            child.type === (node.type === 'module' ? 'group' : 'translation')
          ).length;

          node.children.forEach(child => {
            if (child.type === 'translation') {
              total++;
              // Obtener el valor del servidor para esta clave
              const serverValue = _.get(serverTranslations, child.key);
              const fallbackValue = child.fallback;

              // Es personalizada si existe en el servidor y es diferente del fallback
              if (serverValue !== undefined && serverValue !== fallbackValue) {
                // console.log(`[Debug] Custom translation found in ${child.key}:`, {
                //   fallback: fallbackValue,
                //   server: serverValue
                // });
                customized++;
              }
            } else {
              const childStats = countCustomizations(child);
              total += childStats.total;
              customized += childStats.customized;
            }
          });
        }

        return { total, customized, groups };
      };

      const stats = countCustomizations(item);
      // console.log(`[Stats] ${item.id}:`, stats);
      
      return {
        total: stats.total || 0,
        customized: stats.customized || 0,
        groups: stats.groups || 0
      };
    },

    getFallbackMessages() {
      return getFallbackMessage(this.currentLocale);
    },

    hasMatchingChildren(node, searchTerm) {
      if (!node?.children) return false;
      if (!searchTerm) return true;
      
      return node.children.some(child => {
        if (child.type === 'translation') {
          if (!searchTerm) return true;
          
          const translationValue = String(child.translation || '').toLowerCase();
          const fallbackValue = String(child.fallback || '').toLowerCase();
          const nameValue = String(child.name || '').toLowerCase();

          return nameValue.includes(searchTerm) || 
                 translationValue.includes(searchTerm) || 
                 fallbackValue.includes(searchTerm);
        }
        
        return this.hasMatchingChildren(child, searchTerm);
      });
    },

    onTranslationBlur(item) {
      setTimeout(() => {
        if (!item) return;
        
        const currentValue = item.translation?.trim();
        // Removemos la comparación con originalValue para permitir guardar aunque sea el mismo valor
        this.saveTranslation(item);
        this.activeTranslation = null;
      }, 100);
    },

    expandAll() {
      // Solo permitir expandir si hay un módulo seleccionado o hay búsqueda
      if (!this.selectedModule && !this.search) {
        return;
      }

      this.isExpanded = !this.isExpanded;
      if (this.isExpanded) {
        const allIds = [];
        const collectIds = (items) => {
          if (!items) return;
          items.forEach(item => {
            if (item?.id) {
              allIds.push(item.id);
              if (item.children) {
                collectIds(item.children);
              }
            }
          });
        };
        collectIds(this.moduleTree);
        this.openedModules = allIds;
      } else {
        this.openedModules = [];
      }
    },

    filterTree(items, searchTerm) {
      if (!items?.length) return [];
      
      // Si no hay búsqueda ni módulo seleccionado, retornar items sin filtrar
      if (!searchTerm && !this.selectedModule) {
        return items;
      }

      const searchLower = String(searchTerm || '').toLowerCase();
      
      const filterNode = (item) => {
        if (!item) return null;

        // Primero verificamos si el ítem pertenece al módulo seleccionado
        if (this.selectedModule && item.type === 'module' && item.id !== this.selectedModule) {
          return null;
        }

        // Si es una traducción, verificar coincidencia
        if (item.type === 'translation') {
          if (!searchTerm) return item;

          const translationValue = String(item.translation || '').toLowerCase();
          const fallbackValue = String(item.fallback || '').toLowerCase();
          const nameValue = String(item.name || '').toLowerCase();

          return (nameValue.includes(searchLower) || 
                  translationValue.includes(searchLower) || 
                  fallbackValue.includes(searchLower)) ? item : null;
        }

        // Si es un módulo o grupo, filtrar sus hijos
        if (item.children?.length) {
          const filteredChildren = item.children
            .map(child => filterNode(child))
            .filter(Boolean);

          if (filteredChildren.length > 0) {
            return {
              ...item,
              children: filteredChildren
            };
          }
        }

        return null;
      };

      return items
        .map(item => filterNode(item))
        .filter(Boolean);
    },

    updateFilteredItems() {
      const treeToFilter = [...(this.moduleTree || [])];
      
      try {
        // Primero aplicamos el filtro por módulo
        let filteredByModule = treeToFilter;
        if (this.selectedModule) {
          filteredByModule = treeToFilter.filter(item => item.id === this.selectedModule);
        }

        // Luego aplicamos la búsqueda si existe
        if (this.search) {
          this.filteredTree = this.filterTree(treeToFilter, this.search);
        } else {
          this.filteredTree = filteredByModule;
        }

        // Si no hay resultados después del filtrado
        if (!this.filteredTree.length) {
          // Si hay módulo seleccionado pero no hay búsqueda, mostrar el módulo completo
          if (this.selectedModule && !this.search) {
            this.filteredTree = filteredByModule;
          }
        }

        // Ajustar la paginación
        const maxPage = Math.ceil(this.filteredTree.length / this.itemsPerPage);
        this.page = Math.min(this.page, maxPage || 1);

        // Actualizar estado de expansión
        this.updateExpansionState();
      } catch (error) {
        console.error('Error updating filtered items:', error);
        this.filteredTree = [];
      }
    },

    updateExpansionState() {
      const shouldExpand = this.search || this.selectedModule;
      
      if (shouldExpand && !this.isExpanded) {
        this.openedModules = this.filteredTree.map(item => item.id);
        this.isExpanded = true;
        this.expansionLevel = 1;
      } else if (!shouldExpand) {
        this.openedModules = [];
        this.isExpanded = false;
        this.expansionLevel = 0;
      }
    },

    highlightText(text) {
      if (!this.search || !text) return text;
      const searchRegex = new RegExp(`(${this.escapeRegExp(this.search)})`, 'gi');
      return text.toString().replace(searchRegex, '<mark class="search-highlight">$1</mark>');
    },

    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    },

    isTextTruncated(el) {
      if (!el) return false;
      return el.scrollWidth > el.offsetWidth;
    },

    onTranslationClick(item) {
      if (this.activeTranslation && this.activeTranslation !== item.id) {
        const activeItem = this.findTranslationItem(this.activeTranslation);
        if (activeItem) {
          this.onTranslationBlur(activeItem);
        }
      }

      this.activeTranslation = item.id;
      item.originalTranslation = item.translation || item.fallback;
      
      this.$nextTick(() => {
        const input = this.$refs.translationInput?.$el?.querySelector('input');
        if (input) {
          input.focus();
          input.select();
        }
      });
    },

    onLabelClick(item) {
      // Crear un objeto de traducción para el label
      const labelItem = {
        id: item.labelKey,
        key: item.labelKey,
        translation: item.displayName,
        fallback: item.name,
        type: 'translation',
        isCustom: true,
        saving: false
      };

      this.onTranslationClick(labelItem);
    },

    startLabelEdit(item) {
      this.activeTranslation = item.labelKey;
      // Forzar el foco en el input en el siguiente ciclo
      this.$nextTick(() => {
        const input = this.$refs.translationInput?.$el?.querySelector('input');
        if (input) {
          input.focus();
          input.select();
        }
      });
    },

    updateLabelValue(item, value) {
      item.displayName = value;
    },

    findTranslationItem(id) {
      const findInTree = (items) => {
        for (const item of items) {
          if (item.id === id) {
            return item;
          }
          if (item.children) {
            const found = findInTree(item.children);
            if (found) return found;
          }
        }
        return null;
      };
      
      return findInTree(this.moduleTree);
    },

    findParentModule(item) {
      const moduleId = item.id.split('.')[0];
      const parentModule = this.moduleTree.find(module => module.id === moduleId);
      return parentModule || null;
    },

    isEmptyNode(item) {
      if (!item.children) return false;
      
      if (this.search || this.selectedModule) {
        return !this.hasVisibleChildren(item);
      }
      
      return item.children.length === 0;
    },

    hasVisibleChildren(node) {
      if (!node?.children) return false;
      
      const searchTerm = (this.search || '').toLowerCase();
      
      return node.children.some(child => {
        if (!child) return false;
        
        if (child.type === 'translation') {
          if (!searchTerm) return true;
          
          const translationValue = (child.translation || '').toLowerCase();
          const fallbackValue = (child.fallback || '').toLowerCase();
          const nameValue = child.name.toLowerCase();

          return nameValue.includes(searchTerm) || 
                 translationValue.includes(searchTerm) || 
                 fallbackValue.includes(searchTerm);
        }
        
        return this.hasVisibleChildren(child);
      });
    },

    onTranslationUpdated() {
      this.loadTranslations(true); // re-render from custom translations
    },

    showRestoreDialog() {
      this.showDialog = true;
    },

    async restoreDefaultTranslations() {
      this.restoringTranslations = true;
      try {
        // Enviar un objeto vacío al servidor para restaurar las traducciones
        await LanguajeService.restoreTranslations();
        
        // Recargar traducciones
        await LanguajeService.fetchCustomTranslations(this.currentLocale);
        await this.loadTranslations();
        
        this.$toast.success(this.$t('translations.editor.restoreSuccess'));
      } catch (error) {
        console.error('Error restoring translations:', error);
        this.$toast.error(this.$t('translations.editor.restoreError'));
      } finally {
        this.restoringTranslations = false;
        this.showDialog = false;
      }
    },

    check(permissions) {
      return PermisionService.check(permissions);
    },

    checkAdmin() {
      return PermisionService.checkAdmin();
    },

    handleExpand() {
      // Incrementar el nivel de expansión
      this.expansionLevel = (this.expansionLevel + 1) % 3;
      
      switch (this.expansionLevel) {
        case 0: // Contraer todo
          this.openedModules = [];
          this.isExpanded = false;
          break;
        case 1: // Expandir primer nivel
          this.openedModules = this.filteredTree.map(module => module.id);
          this.isExpanded = true;
          break;
        case 2: // Expandir todo
          const allIds = [];
          const collectIds = (items) => {
            if (!items) return;
            items.forEach(item => {
              if (item?.id && !this.isEmptyNode(item)) {
                allIds.push(item.id);
                if (item.children) {
                  collectIds(item.children);
                }
              }
            });
          };
          collectIds(this.filteredTree);
          this.openedModules = allIds;
          this.isExpanded = true;
          break;
      }
    },

    handlePageChange({ page }) {
      if (page === this.page) return;
      
      // Guardar el estado actual de expansión
      const currentOpenedModules = [...this.openedModules];
      
      this.page = page;
      
      this.$nextTick(() => {
        // Restaurar el estado de expansión después de la paginación
        this.openedModules = currentOpenedModules;
        window.scrollTo(0, 0);
      });
    },
  },

  watch: {
    search: {
      handler(newValue) {
        // Asegurarse de que newValue sea string
        const searchValue = String(newValue || '');
        if (!searchValue && this.selectedModule) {
          // Si se limpia la búsqueda y hay un módulo seleccionado,
          // volvemos a mostrar solo el módulo
          this.updateFilteredItems();
        } else {
          this.updateFilteredItems();
        }
      },
      immediate: true
    },
    selectedModule: {
      handler(newValue) {
        // Limpiar búsqueda si cambiamos de módulo
        if (newValue && this.search) {
          this.search = '';
        }
        this.updateFilteredItems();
      }
    },
    currentLocale: {
      immediate: true,
      handler(newLocale) {
        if (newLocale) {
          this.$i18n.locale = newLocale;
        }
      }
    },
    openedModules: {
      handler(newValue) {
        // Solo actualizar el nivel de expansión si hay un módulo o búsqueda activa
        if (this.search || this.selectedModule) {
          if (newValue.length === 0) {
            this.expansionLevel = 0;
            this.isExpanded = false;
          } else if (newValue.length === this.filteredTree.length) {
            this.expansionLevel = 1;
            this.isExpanded = true;
          } else if (newValue.length > this.previousOpenedLength) {
            this.expansionLevel = 2;
            this.isExpanded = true;
          }
        }
        this.previousOpenedLength = newValue.length;
      },
      deep: true
    }
  },

  async created() {
    if (!this.canView) {
      this.$router.push({ name: 'home' });
      return;
    }
    // Guardar el idioma original del usuario
    this.originalLocale = i18n.locale;
    // Cargar traducciones con filtrado de módulos
    await LanguajeService.fetchCustomTranslations(i18n.locale, true);
    await this.loadTranslations();
  },

  beforeDestroy() {
    // Restaurar el idioma original del usuario al salir
    if (this.originalLocale && this.originalLocale !== i18n.locale) {
      LanguajeService.changeLanguaje(LanguajeService.getLenguajeNameByKey(this.originalLocale));
    }
  }
};
</script>

<style scoped>
@import '../../../assets/scss/views/_translations.scss';
</style>