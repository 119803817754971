<template>
  <div class="translations-header">
    <!-- Title Section -->
    <v-row no-gutters align="center" class="mb-6">
      <v-col>
        <QuickTranslation
          translation-key="translations.label"
          tag="h3"
          @translation-updated="$emit('translation-updated')"
          class="header-title"
        />
      </v-col>
      <v-col cols="auto">
        <v-fade-transition>
          <v-select 
            outlined 
            dense 
            :value="currentLanguage" 
            :items="languageItems" 
            item-text="text"
            item-value="value" 
            :label="$t('translations.editor.selectLanguage')" 
            class="language-select"
            @change="onLanguageChange" 
            :hide-details="true"
            prepend-inner-icon="mdi-translate"
          ></v-select>
        </v-fade-transition>
      </v-col>
    </v-row>

    <!-- Controls Section -->
    <v-card class="controls-card" flat>
      <v-row no-gutters align="center">
        <!-- Left Side Controls -->
        <v-col class="d-flex align-center">
          <v-select
            outlined
            dense
            v-model="selectedModule"
            :items="moduleItems"
            item-text="text"
            item-value="value"
            :label="$t('translations.editor.selectModule')"
            :placeholder="$t('translations.editor.selectModulePlaceholder')"
            clearable
            class="module-select"
            :hide-details="true"
            @change="onModuleChange"
            prepend-inner-icon="mdi-folder-outline"
          ></v-select>

          <v-text-field 
            v-model="searchQuery" 
            :label="$t('translations.editor.search')" 
            :placeholder="$t('translations.editor.searchPlaceholder')"
            append-icon="mdi-magnify" 
            outlined
            dense 
            clearable 
            :hide-details="true" 
            class="search-field ml-4"
            @input="handleSearchInput"
          ></v-text-field>
        </v-col>

        <!-- Right Side Controls -->
        <v-col cols="auto" class="d-flex align-center">
          <v-btn 
            elevation="0" 
            :color="getExpandButtonColor"
            @click="handleExpand"
            :disabled="!canExpand"
            class="expand-button mr-4"
          >
            <v-icon left small>
              {{ getExpandIcon }}
            </v-icon>
            {{ getExpandText }}
          </v-btn>

          <v-btn
            v-if="canEdit"
            color="error"
            outlined
            @click="$emit('restore')"
            :loading="loading"
            class="restore-button"
          >
            <v-icon left small>mdi-restore</v-icon>
            {{ $t('translations.editor.restoreDefault') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import QuickTranslation from './QuickTranslation.vue';
import PermisionService from '@/services/PermisionService';

export default {
  name: 'TranslationsHeader',
  
  components: {
    QuickTranslation
  },

  props: {
    moduleItems: {
      type: Array,
      default: () => []
    },
    languageItems: {
      type: Array,
      required: true
    },
    isExpanded: {
      type: Boolean,
      default: false
    },
    hasItems: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    currentLanguage: {
      type: String,
      required: true
    },
    hasSearch: {
      type: Boolean,
      default: false
    },
    moduleSelected: {
      type: Boolean,
      default: false
    },
    expansionLevel: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      selectedModule: null,
      selectedLanguage: null,
      searchQuery: ''
    };
  },

  computed: {
    canEdit() {
      return this.checkAdmin() || this.check([{ domain: 'Translations', permisions: ['Write'] }]);
    },
    canExpand() {
      // Solo habilitar el botón si hay items Y (hay búsqueda O hay módulo seleccionado)
      return this.hasItems && (this.hasSearch || this.moduleSelected);
    },
    searchExpansionLevel() {
      if (this.hasSearch) return 2;
      if (this.moduleSelected) return 1;
      return this.expansionLevel;
    },
    getExpandButtonColor() {
      if (this.expansionLevel === 2) return 'grey lighten-2';
      return 'primary';
    },
    getExpandIcon() {
      switch (this.expansionLevel) {
        case 0:
          return 'mdi-chevron-right';
        case 1:
          return 'mdi-chevron-double-right';
        case 2:
          return 'mdi-chevron-down';
        default:
          return 'mdi-chevron-right';
      }
    },
    getExpandText() {
      switch (this.expansionLevel) {
        case 0:
          return this.$t('translations.editor.expandFirstLevel');
        case 1:
          return this.$t('translations.editor.expandAll');
        case 2:
          return this.$t('translations.editor.collapseAll');
        default:
          return this.$t('translations.editor.expandFirstLevel');
      }
    }
  },

  methods: {
    check(permissions) {
      return PermisionService.check(permissions);
    },

    checkAdmin() {
      return PermisionService.checkAdmin();
    },

    onLanguageChange(newValue) {
      this.selectedLanguage = newValue;
      this.$emit('language-change', newValue);
    },

    handleSearchInput(value) {
      this.searchQuery = value;
      if (!value && this.selectedModule) {
        // Si se limpia la búsqueda y hay un módulo seleccionado,
        // mantenemos el filtro del módulo
        this.$emit('search', '');
      } else {
        this.$emit('search', value);
      }
    },

    onModuleChange(value) {
      this.selectedModule = value;
      if (this.searchQuery) {
        // Si hay una búsqueda activa, la limpiamos
        this.searchQuery = '';
        this.$emit('search', '');
      }
      this.$emit('module-change', value);
    },

    handleExpand() {
      this.$emit('handle-expand');
    }
  }
};
</script>

<style scoped>
.translations-header {
  padding: 16px 0;
}

.header-title {
  font-size: 1.75rem;
  font-weight: 500;
}

.controls-card {
  background: rgba(0, 0, 0, 0.02) !important;
  border-radius: 8px !important;
  padding: 16px;
}

.module-select {
  max-width: 200px;
}

.search-field {
  max-width: 500px;
}

.language-select {
  max-width: 200px;
}

.expand-button {
  text-transform: none !important;
  letter-spacing: 0 !important;
  font-weight: 500 !important;
  min-width: 140px !important;
  transition: all 0.3s ease !important;
}

.expand-button:not(:disabled) {
  opacity: 1 !important;
}

.expand-button:disabled {
  opacity: 0.6 !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
  color: rgba(0, 0, 0, 0.38) !important;
}

.expand-button.white--text {
  color: white !important;
}

.restore-button {
  text-transform: none;
  letter-spacing: 0;
}

:deep(.v-input__prepend-inner) {
  margin-top: 8px !important;
  margin-right: 8px !important;
  opacity: 0.7;
}

:deep(.v-input__slot) {
  min-height: 40px !important;
}

:deep(.v-btn) {
  min-height: 40px !important;
}
</style>
